/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2017.

!Changelog

2017
	01-03-2018
	- [ADDED] Added document.ready to onready.
	30-08-2017
	- [BORN] This class is born. Hooray!

!Usage

	specific_videoplayer.onready();
	
!Wishlist

	none
	
*/
  
var specific_marquee = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	/**
	None yet
	**/
	 
	// METHODS  *****************************************************************
	// METHODS *****************************************************************
	// METHODS ******************************************************************
	// METHODS ******************************************************************
	
	this.onready = function() {

		 specific_marquee.screenHeightCheck();

	};


	/*
	Highlight
	*/
	this.highlight = function() {

		_random_1_a = 0;

		/*
		Highlight
		*/
		_marqueeHighlight = setInterval(function() {

			var _random_array = [];

			$('.js-marquee').map(function() {

				if(
					inViewport($(this))
				) {

					_random_array.push($(this).data('id'));

				}

			});

			/*
			Delete first and lasts
			*/
			_random_array.pop();
			_random_array.pop();
			_random_array.pop();
			_random_array.pop();

			_random_array.shift();
			_random_array.shift();
			_random_array.shift();
			_random_array.shift();
			

			var _highlights 	= $('.js-marquee');

			var _random_1 		= Math.floor(Math.random() * _random_array.length);
			var _random_1_new	= _random_array[_random_1];

			/*
			Same?!
			*/
			if(_random_1_new != _random_1_a) {

				_random_1_a 	= _random_1_new;

			} else {

				_random_1 		= Math.floor(Math.random() * _random_array.length);
				_random_1_a		= _random_array[_random_1];

			}


			/*
			On / Off
			*/
			var _opacity_on 	= 0;
			var _opacity_off 	= 0;

			if(_device == 'desktop') {
				_opacity_on 	= 0.4;
				_opacity_off 	= 0.1;
			} else {
				_opacity_on 	= 0.1;
				_opacity_off 	= 0.05;
			}

			/*
			Off
			*/
			_highlights.css({
				opacity:_opacity_off,
				color:'#03f'
			});

			/*
			On
			*/
			$('.js-marquee[data-id="' + _random_1_a + '"]').animate({
				opacity:_opacity_on,
				color:'#ed4409'
			},500);

		},1500);

	};

	/*
	Check screenheight
	*/
	this.screenHeightCheck = function() {

		/*
		Interval: Check for height
		*/
		_marqueeHeight = setInterval(function() {

			/*
			Get: Offset - top
			*/
			var _offset_top 	= Number(Math.abs(parseInt($('.marquee-wrapper').css('top'))));
			var _window_offset 	= Number(Math.abs($('.marquee-wrapper').height()));
				_window_offset  = (_window_offset - $(window).height());

			if(
				_offset_top > _window_offset
			) {
					
				specific_marquee.stop();

			}

		},1000);

	};

	this.stop = function() {
		
		/*
		Reset
		*/
		$('.marquee-wrapper').css(
			'top',0
		);

		/*
		Clear Intervals
		*/
		if(typeof _marqueeHeight != 'undefined') {
			clearInterval(_marqueeHeight);
		}
		if(typeof _marqueeHightlight != 'undefined') {
			clearInterval(_marqueeHightlight);
		}

	};

	this.marquee = function() {
		
		console.log('1');

		/*
		Marquee
		*/
		$('.marquee-wrapper').stop().animate({
			'top':'-' + $('.marquee-wrapper').height()
		},300000,'linear');

	};
		
}).apply(specific_marquee); 
/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	17-10-2018
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_interactions_init = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	/**
	None yet
	**/
	 
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	this.onready = function() {
		
		/**
		Unbind: Unnecessary listeners
		**/
		//$(document).off('click.cookieWallSubmit');
		//$(document).off('click.cookieWallTabs');
		//$(document).off('click.cookieBarSubmit');
		
		/*
		Close the mobilemenu always on route
		*/
		specific_mobilemenu.toggle(1);

		/*
		Trigger: Instagram
		*/
		//specific_instagram.onready('thumb',8,true);

		/*
		Trigger: Facebook
		*/
		//specific_facebook.onready(6,true);

		/*
		Catch and active URL
		*/
		var _url_active_main = './';
		
		$('.js-nav-item').removeClass('active');
		
		if(url[2] !== '') { 
			_url_active_main = url[1] + '/' + url[2]; 
		} else {
			if(url[1] !== '') { 
				_url_active_main = url[1];
			}
		}

		if(_url_active_main !== '') {
			$('.js-nav-item[data-go-url="' + _url_active_main + '"]').addClass('active');
		}

		/*
		Trigger: classes
		*/
		// none yet

		/*
		Nicescroll
		*/
		// $('.content--project').niceScroll({
		// 	mousescrollstep:50,
		// 	scrollspeed:250
		// });

		/*
		Project
		*/
		if(
			url[1] != '' && 
			$(window).width() > 750
		) {

			$('.js-content').hide();

			var _height = $('.js-inside').outerHeight() / 2;

			$('.js-content').css({
				'margin-top':_height
			});

			/*
			Clear: Marquee intervals
			*/
			if(typeof _marqueeHeight != 'undefined') {
				clearInterval(_marqueeHeight);
			}
			if(typeof _marqueeHighlight != 'undefined') {
				clearInterval(_marqueeHighlight);
			}

			$('.js-content').fadeIn(1000);

		} else {

			if(url[1] != '') {

				$('html, body').animate({
					scrollTop: $(".js-content").offset().top - 20
				}, 1000, 'swing');

				/*
				Clear: Marquee intervals
				*/
				if(typeof _marqueeHeight != 'undefined') {
					clearInterval(_marqueeHeight);
				}
				if(typeof _marqueeHighlight != 'undefined') {
					clearInterval(_marqueeHighlight);
				}

				$('.js-content').fadeIn(1000);
			}

		}

	};
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
	// none
	
}).apply(specific_interactions_init); 

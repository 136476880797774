/*
Version 0.110

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	08-03-2019
	- [CHANGE] Added variable check to set height and with when not given in on windowResize();
	11-10-2018
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_interactions_window = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	// none
	
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
		/**
		Window: resize
		**/
		this.windowResize = function(scrolled,h,w) {
			
			/*
			Set: Variables
			*/
			if(typeof h === 'undefined') {
				h = $(window).height();
			}
			if(typeof w === 'undefined') {
				w = $(window).width();
			}

			/*
			Fix: 100% Height mobile
			*/
			document.querySelector(':root').style.setProperty('--vh', window.innerHeight/100 + 'px');

			/*
			Marquee
			*/
			specific_marquee.stop();
			
			/*
			Project
			*/
			if(url[1] != '') {

				_is_marquee_started = 0;

				var _height_nav 	= $('.js-inside').outerHeight();
				var _height_nav_top = $('.js-inside').offset().top;
				var _height 		= _height_nav / 2;

				if(w > 750) {
					if(
						_height_nav >= h
					) {

						$('.js-content').css({
							'margin-top':0
						});

					} else {

						$('.js-content').css({
							'margin-top':_height_nav_top
						});

					}

				} else {

					$('.js-content').css({
						'margin-top':0
					});


				}

			} else {

				if(_is_marquee_started == 0) {

					/*
					Marquee
					*/
					specific_marquee.marquee();

					/*
					Start: Highlight
					*/
					specific_marquee.highlight();

					_is_marquee_started = 1;

				}

			}
			
		};
		
		/**
		Window: scroll
		**/
		this.windowScroll = function(scrolled,h,w) {

		};
		
		
		/**
		Window: orientation
		**/
		this.windowOrientationChange = function() {
			
		};
		
}).apply(specific_interactions_window); 
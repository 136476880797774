/*
Version 0.140

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2017 - 2019.

!Changelog

2019
	27-04-2019
	- [CHANGE[ Commenting
	- [CHANGE] Added new variabeles loadDateTimepicker and loadDatepicker
	27-03-2019
	- [CHANGE] Delete variable: PiwikID.
2018
	19-05-2018
	- [CHANGE] New variable: isModalOpenOnFormProblem
	16-04-2018
	- [ADDED] New variable: ajaxRequest and isCompressImages
	28-02-2018
	- [ADDED] New variable: loadTinyMCE
2017
	13-10-2017
	- [VAR] Added: $.datepicker.regional.nl is now added for using datepicker language.
	08-10-2017
	- [VAR] Added: New vars like Project specifics.
	30-08-2017
	- [VAR] Changed isSmartphoneMenuOpen to isMobileMenuOpen
	- [VAR] New vars added: historyLatestPage,historyLatestPopup,historyLatestPopupPage and historyLatestPopupTitle
	29-08-2017
	- [VAR] urlParts is now url[];
	16-02-2017
	- [VARIABLES] Added: new var to fill with title; seo_title_old
	05-01-2017
	- [CHANGE] Changed: The preview URL can now be like preview-other var..like; /preview-v2 etc..
	
!Wishlist
 
	// none

*/

/* SETTINGS **************************************************************** */
/* SETTINGS **************************************************************** */
/* SETTINGS **************************************************************** */
/* SETTINGS **************************************************************** */

/*
Debugging
*/
var urlAlert 					= false;

/*
Helpers and Global Variables
*/
var isFirstLoad					= 1;
var isLoading					= 1;

var isPopupOpen 				= 0;
var isModalOpen 				= 0;
var isModalOpenOnFormProblem	= 0;
var isSmartphone 				= 0;
var isMobileMenuOpen			= 0;
var isOrientation				= 0;
var isScrolledNow 				= 0;
var isFormProcess				= 0;
var isUploadProcess 			= 0;
var ajaxRequest					= '';

	/*
	Project specifics
	*/
	var loadTinyMCE				= 0;
	var loadDatepicker			= 0;
	var loadDateTimepicker		= 0;
	
	var isCompressImages		= 0;
	var pollConnection			= 0;
	
	var _is_marquee_started 	= 0;
	
/*
SEO Change title
*/
var seoTitleOld = '';

/*
Form and notifier coloring
*/
var formNormalColorBackground 		= '#ffffff';
var formNormalColorText 			= '#000000';
var formReadOnlyColorBackground 	= '#cccccc';
var formReadOnlyColorText 			= '#000000';

var formErrorColorBackground 		= '#f19c9c';
var formErrorColorText 				= '#000000';

var notifierNormalBackgroundColor 	= '#ffffff';
var notifierNormalTextColor 		= '#000000';
var notifierErrorBackgroundColor 	= '#DC143C';
var notifierErrorTextColor 			= '#ffffff';

/*
Get Protocol and Host and projectUrl automatically
*/
projectProtocol 	= window.location.protocol;
projectHost 		= window.location.host;
projectUrl 			= window.location.pathname;
projectUrl 			= projectUrl.split('/');

/*
Datepicker & DateTimepicker languages array variables
*/
if(loadDatepicker === 1) {
	$.datepicker.regional.nl = {
		closeText: 			"Sluiten",
		prevText: 			"←",
		nextText: 			"→",
		currentText: 		"Vandaag",
		monthNames: 		[ "januari", "februari", "maart", "april", "mei", "juni","juli", "augustus", "september", "oktober", "november", "december" ],
		monthNamesShort: 	[ "jan", "feb", "mrt", "apr", "mei", "jun","jul", "aug", "sep", "okt", "nov", "dec" ],
		dayNames: 			[ "zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag" ],
		dayNamesShort: 		[ "zon", "maa", "din", "woe", "don", "vri", "zat" ],
		dayNamesMin: 		[ "zo", "ma", "di", "wo", "do", "vr", "za" ],
		weekHeader: 		"Wk",
		dateFormat:			"dd-mm-yy",
		firstDay: 			1,
		isRTL: 				false,
		showMonthAfterYear: false,
		yearSuffix: 		"" 
	};
}

if(loadDateTimepicker === 1) {
	$.timepicker.regional.nl = {
		timeOnlyTitle: 	'Tijd',
		timeText: 		'Tijd',
		hourText: 		'Uren',
		minuteText: 	'Minuten',
		secondText: 	'Seconden',
		millisecText: 	'Milliseconde',
		timezoneText: 	'Tijdzone',
		currentText: 	'Nu',
		closeText: 		'Klaar',
		timeFormat: 	'HH:mm',
		amNames: 		['AM', 'A'],
		pmNames: 		['PM', 'P'],
		isRTL: false
	};
}
			
/*
Check if host is localhost, GoServer or Preview on location or LIVE.
*/
switch(projectHost) {
	
	case 'localhost':
	case 'localhost:3000':
	case 'localhost:3002':
	case 'preview.goserver.nl':
	case '192.168.1.77':
		subUrl = '/' + projectUrl[1];
	break;
	
	default:
		if (projectUrl[1].substr(0,7) == 'preview') {
			subUrl = '/preview';
			// else if (projectUrl[1].substr(0,11) == 'inthemaking') {
			// subUrl = '/inthemaking/' + projectUrl[2]; 
		} else {
			subUrl = '';
		}
	break;
	
}
/*
Count URL lengts
*/		
globalUrl 					= projectProtocol + '//' + projectHost + subUrl;
globalUrlLength 			= subUrl.length;

/*
Get array of URL Parts
*/
url 						= getUrl();

/*
Render new SubUrl
*/
newSubUrl 					= renderSubUrl();

/*
Set new history url and document title
*/
historyURL 					= document.URL;
historyDocumentTitle 		= document.title;

historyLatestPageURL 		= '';
historyLatestPageTitle 		= '';
historyLatestPopupURL 		= '';
historyLatestPopupTitle 	= '';

/*
Debug: Show page
*/
if(urlAlert === true) { console.log(page); }
